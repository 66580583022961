@keyframes animate-block {
    0% { opacity: 1; }
    50% { opacity: 0.3; }
    100% { opacity: 1; }
}

.block-animate{
    padding: 5px 10px 5px 10px;
    background-color: #DE165F30;
    border-radius: 5px;
    color: #DE165F;
    text-align: center;
    margin-left: 25%;
    font-size: 25px;
    animation: animate-block 3s linear infinite;
}