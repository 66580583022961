@keyframes toast-animate {
    0% { opacity: 1; }
    50% { opacity: 0.3; }
    100% { opacity: 1; }
}

.toast{
    padding: 5px 10px 5px 10px;
    background-color: #DE165F30;
    border-radius: 5px;
    color: #DE165F;
    animation: animate-block 3s linear infinite;
}