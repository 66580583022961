@keyframes loading-text {
    0% { opacity: 1; }
    50% { opacity: 0.3; }
    100% { opacity: 1; }
}

.loading-text{
    margin-right: 17px;
    padding: 1px 2px 1px 2px;
    background-color: #8626D130;
    border-radius: 5px;
    color: #8626D1;
    width: 390px;
    font-weight: bold;
    /* animation: loading-text 3s linear infinite; */
    text-align: center;
}

.morphing-blob-wrapper {
    position: absolute;
    top: 50%;
    left: 65%;
    transform: scale(1.9) translate(-25%,-25%);
    opacity: .15;
    z-index: -1;
}

@-webkit-keyframes levitate {
    0% {
        transform: translate(0)
    }

    50% {
        transform: translateY(10px)
    }

    to {
        transform: translate(0)
    }
}

@keyframes levitate {
    0% {
        transform: translate(0)
    }

    50% {
        transform: translateY(10px)
    }

    to {
        transform: translate(0)
    }
}

.levitate {
    -webkit-animation-name: levitate;
    animation-name: levitate;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
}


/* .morphing-blob-wrapper.is-secondary svg[data-v-34423847],.morphing-blob-wrapper.is-secondary svg circle[data-v-34423847] {
    fill: var(#8257E6)
}

.morphing-blob-wrapper svg[data-v-34423847],.morphing-blob-wrapper svg circle[data-v-34423847] {
    fill: var(#8257E6)
} */

.morphing-blob-wrapper .circle-1[data-v-34423847] {
    -webkit-animation: from0to360-34423847 3s linear infinite;
    animation: from0to360-34423847 3s linear infinite;
    transform-origin: 145px 150px
}

.morphing-blob-wrapper .circle-2[data-v-34423847] {
    -webkit-animation: from360to0-34423847 4s linear infinite;
    animation: from360to0-34423847 4s linear infinite;
    transform-origin: 155px 150px
}

.morphing-blob-wrapper .circle-3[data-v-34423847] {
    -webkit-animation: from0to360-34423847 5s linear infinite;
    animation: from0to360-34423847 5s linear infinite;
    transform-origin: 150px 145px
}

.morphing-blob-wrapper .circle-4[data-v-34423847] {
    -webkit-animation: from360to0-34423847 4.5s linear infinite;
    animation: from360to0-34423847 4.5s linear infinite;
    transform-origin: 150px 155px
}